import {
  MERCHANT_DATA,
  PURCHASE_ORDER_DATA,
  PURCHASE_ORDER_NUMBER,
  USER_LOGOUT,
  EMPLOYEE_CLOCKIN_RANGE,
  EMPLOYEE_ID_FOR_CLOCKINREPORT,
  EMPLOYEE_CLOCKIN_DATE,
  EMPLOYEE_NAME,
  CLOCKINOUT_DATE,
  PURCHASE_ORDER_PLACE_DATA,
  CHECK_ORDER_DATA,
} from "../constants";
const initialState = {
  merchantData: {},
  orderData: {},
  orderPlaceData: [],
  orderNumber: {},
  empClockInDate: {},
  empClockInRange: [],
  empIdForReport: {},
  empName: {},
  clockInOutDate: {},
  isOrderValue: false,
};
const merchantReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MERCHANT_DATA:
      return { ...state, merchantData: payload };
    case USER_LOGOUT:
      return {
        merchantData: {},
        orderData: {},
        orderNumber: {},
        empClockInDate: {},
        empClockInRange: [],
        orderPlaceData: {},
        empIdForReport: {},
        empName: {},
        clockInOutDate: {},
      };
    case PURCHASE_ORDER_DATA:
      return { ...state, orderData: payload };
    case PURCHASE_ORDER_PLACE_DATA:
      return { ...state, orderPlaceData: payload };
    case CHECK_ORDER_DATA:
      return { ...state, isOrderValue: payload };
    case PURCHASE_ORDER_NUMBER:
      return { ...state, orderNumber: payload };
    case EMPLOYEE_CLOCKIN_RANGE:
      return { ...state, empClockInRange: payload };
    case EMPLOYEE_CLOCKIN_DATE:
      return { ...state, empClockInDate: payload };
    case EMPLOYEE_ID_FOR_CLOCKINREPORT:
      return { ...state, empIdForReport: payload };
    case EMPLOYEE_NAME:
      return { ...state, empName: payload };
    case CLOCKINOUT_DATE:
      return { ...state, clockInOutDate: payload };
    default:
      return state;
  }
};
export default merchantReducer;
