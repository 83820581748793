import "antd/dist/antd.min.css";
import "./App.css";
import "./Responsive.css";
import Routing from "./routing/Routing";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { statusOfOrderDataValue } from "./redux/actions/merchantAction";
import { useDispatch } from "react-redux";
import { QuestionOutlined } from "@ant-design/icons";
import { red } from "@ant-design/colors";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isOrderDataAvailable = useSelector(
    (store) => store.merchantReducer.isOrderValue
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (
      !location?.pathname?.includes("purchase-order") &&
      isOrderDataAvailable
    ) {
      setIsModalOpen(true);
    }
  }, [location, isOrderDataAvailable]);
  const handleCoseModal = () => {
    navigate("/storemanagement/purchase-order");
    setIsModalOpen(false);
  };
  const handleConfirm = () => {
    dispatch(statusOfOrderDataValue(false));
    navigate(location.pathname);
    setIsModalOpen(false);
  };

  return (
    <div className="App">
      <Routing />
      <Modal
        size="lg"
        show={isModalOpen}
        onHide={handleCoseModal}
        aria-labelledby="example-modal-sizes-title-lg contained-modal-title-vcenter"
        centered
        className="modal-dialog-centered"
      >
        <div>
          <Modal.Header className="handle" closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Confirmation Modal
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-12">
              <div className="box-img-center add">
                <QuestionOutlined style={{ color: red[5] }} />
              </div>
              <div className="col-md-12 text-oncenter">
                <h5>Are you sure you want to leave this page?</h5>
              </div>
            </div>
            <div className="text-center mt-5">
              <button
                className="btn vik-buttonOne"
                type="button"
                onClick={handleConfirm}
              >
                Yes
              </button>
              <button
                className="btn vik-buttonTwo"
                type="button"
                onClick={handleCoseModal}
              >
                No
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
}
export default App;
