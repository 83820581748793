import { PRODUCT_TABLE_COLUMN_DATA } from "../constants";
const initialState = {
  version: 1.6,
  productTableColumn: [
    { name: "Product Name", show: true, key: "itemName" },
    { name: "UPC/SKU", show: true, key: "upc" },
    { name: "Department Name", show: true, key: "departmentName" },
    { name: "Vendor Name", show: true, key: "vendorName" },
    { name: "Selling Price", show: true, key: "price" },
    { name: "Cost Price", show: true, key: "costPrice" },
    { name: "Stock", show: true, key: "quantity" },
    { name: "EBT Allow", show: true, key: "isEligibleEBT" },
    { name: "CRV Fee", show: false, key: "crvTotalAmount" },
    { name: "Vendor Item #", show: false, key: "vendorItemNumber" },
    { name: "Vendor Number", show: false, key: "vendorNumber" },
    { name: "CONA Item #", show: false, key: "conaItemNumber" },
    { name: "Vendor Description", show: false, key: "vendorDescription" },
    { name: "Unit of Measure", show: false, key: "unitOfMeasure" },
    { name: "Bin Location", show: false, key: "binLocation" },
    { name: "Inventory Cost", show: true, key: "inventoryCost" },
    { name: "Inventory Retail", show: true, key: "inventoryRetail" },
  ],
};
const productReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PRODUCT_TABLE_COLUMN_DATA:
      const copy = [...state.productTableColumn];
      copy[payload].show = !copy[payload].show;
      return { ...state, productTableColumn: copy };
    default:
      return state;
  }
};
export default productReducer;
