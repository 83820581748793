export const NAME_REQUIRED_MSG = "Name is required.";
export const EMAIL_REQUIRED_MSG = "Email is required.";
export const PHONE_REQUIRED_MSG = "Mobile number is required.";
export const VALID_NAME_MSG = "Please enter valid name.";
export const VALID_EMAIL_MSG = "Please enter valid email.";
export const MIN_THREE_CHAR_REQ = "Minimum 3 characters are required.";
export const ONLY_NUMBER_ALLOW = "Only number allow here.";
export const ONLY_TEN_NUMBER_ALLOW = "Number must be 10 digits.";
export const VALID_ADDRESS_MSG = "Please enter valid address.";
export const VALID_MOBILE_NUMBER = "Enter valid mobile number.";
export const DEPARTMENT_NAME_REQUIRED = "Department name is required.";
export const SELECT_ANY_VALUE = "Please select any value.";
export const CUSTOMER_AGE_REQUIRED = "Please enter customer age.";
export const SELECT_ATLEAST_ONE_TAX = "Please select at least one tax.";
export const MERCHANT_NAME_REQUIRED = "Merchant name is required.";
export const PRODUCT_NAME_REQUIRED = "Product name is required.";
export const SELLING_PRICE_REQUIRED = "Selling price is required.";
export const UPC_REQUIRED = "UPC is required.";
export const PLEASE_SELECT_ANY_DEPARTMENT =
  "Please select at least one department.";
export const CONA_ITEM_NUMBER_REQUIRED = "Please enter CONA item number.";
export const COST_PRICE =
  "Value should be less than or equal to the selling price.";
export const MENU_NAME_MSG = "Please enter the menu name.";
export const PROMOTION_NAME_MSG = "Promotion name is required.";
export const START_DATE = "Start date is required.";
export const END_DATE = "End date is required.";
export const QTY_MSG = "Quantity is required.";
export const PROMOTION_MIN_CHAR =
  "Promotion name must be more than 3 characters.";
export const VALUE_REQ = "Value is required.";
export const FIXED_FEE_MSG = "Fixed fee is required.";
export const TAX_PERCENTAGE_MSG = "Tax percentage is required.";
export const ADDRESS_MSG = "Please enter address.";
export const CITY_NAME_MSG = "Please enter your city name.";
export const STATE_NAME_MSG = "Please enter state name.";
export const ZIPCODE_MSG = "Please enter Zip Code.";
export const ONLY_FIVE_NUMBER_MSG = "Only five number allow.";
export const CUSTOMER_NUM_MSG = "Please enter customer number.";
export const TAX_ID_MSG = "Please enter tax ID.";
